import { ElementRef, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DealerInquiriesService } from './dealer-inquiries.service';
import { AppConstants } from '../app.constants';
import { country } from '../interfaces/common.interface';
import { contactUsPostDataInterface } from '../interfaces/request-interfaces/contact-us.interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CommanpageService {
  private endpoint: string = environment.apiUrl;

  firstObj: number = this.appConstants.firstObj;
  countries: any[] = [];
  selectedCountry: string = 'US';
  states: string[] = [];
  postData = { "country": this.selectedCountry}
  public searchByVehicleData : any = null;

  constructor(
    private appConstants: AppConstants,
    private http: HttpClient,
    private contactHttp: DealerInquiriesService,
    private router: Router
  ) {}

  pagenotfound(){
    this.router.navigate(['/pagenotfound']);
  }

  commanpageList() {
    return this.http.get(this.endpoint + 'footer-menu-content');
  }

  aboutContent(){
    return this.http.get(this.endpoint + 'footers/About');
  }

  termConditionContent(){
    return this.http.get(this.endpoint + 'footers/Terms & Conditions');
  }

  privacyPolicycontent(){
    return this.http.get(this.endpoint + 'footers/Privacy Policy');
  }

  contactUsContant() {
    return this.http.get(this.endpoint + 'footers/contact-us');
  }

  careersContent(){
    return this.http.get(this.endpoint + 'footers/Careers' );
  }

  postContactForm(data: contactUsPostDataInterface): Observable<any> {
    return this.http.post(this.endpoint + 'contact-us', data);
  }

  contactUsContent(){
    return this.http.get(this.endpoint + 'footers/');
  }

  getCountryStateData(): Observable<{ countries: any[], states: any[] }> {
    const data: country = { country: '' }
    return this.contactHttp.countryStateDropdown(data).pipe(
      map(response => {
        if(response && response[this.firstObj]?.data?.countries && response[this.firstObj]?.data?.states){
          const countries = Object.entries(response[this.firstObj].data.countries).map(([code, name]) => ({ code, name }));
          const states = Object.entries(response[this.firstObj].data.states).map(([code, name]) => ({ code, name }));
          return { countries, states };
        }
        else{
          throw new Error('Invalid response format');
        }
      })
    );
  }

  getStatesByCountry(countryCode: string): Observable<{ code: string, name: string }[]> {
    return this.contactHttp.countryStateDropdown({ country: countryCode }).pipe(
      map(response => {
        if (response && response[this.firstObj]?.data?.states) {
        return Object.entries(response[this.firstObj].data.states).map(([code, name]) => ({ code, name: name as string }));
        } else {
          return [];
        }
      })
    );
  }

  setUrlNameField(name: string){
    const specialChars = /[,.\-  :%]/g;
    let transformedString = name?.replaceAll(specialChars,'-');
    let result = transformedString;
    let iterations = 0;
    while(transformedString?.includes('--') && iterations <= 2000) {
      result = transformedString?.replaceAll('--','-');
      iterations++;
    }
    return result;
  }

  setUrlVideoNameField(name: string): string {
    const specialChars = /[,.\-  %]/g;
    let transformedString = name?.replace(specialChars, '-');
    transformedString = transformedString?.replace(/--+/g, '-');
    transformedString = transformedString?.replace(/^-|-$/g, '');
    return transformedString;
}

  setUrlSkuNameField(skuName: string){
    const specialChars = /[ ]/g;
    let transformedString =skuName.replace(specialChars,'-');
  }

  arraySum(arr: Array<number>): number{
    const sum = arr.reduce((acc,item)=>{
      return acc+item;
    },0.00);

    return sum;
  }


  isEmpty(value) {
    for (let prop in value) {
      if(value.hasOwnProperty(prop)) return false;
    }
    return true;
  }

  initIntersectionObserver(ref: ElementRef, loadMoreCallback: () => void): () => void {
    const options = {
      root: null,
      rootMargin: '200px', // Trigger when the target is 200px into the view
      threshold: 1.0, // Trigger when the target is fully in view
    };
    const observer = new IntersectionObserver((entries, obs) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadMoreCallback();
        }
      });
    }, options);
    observer.observe(ref.nativeElement);
   
    return () => observer.disconnect();
  }

}
