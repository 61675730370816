import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AppConstants } from 'src/app/app.constants';

import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { SearchByVehicleService } from 'src/app/service/search-by-vehicle.service';
import { CommanpageService } from 'src/app/service/commanpages.service';
import { CategoryBreadcrumbComponent } from '../../category-breadcrumb/category-breadcrumb.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-sub-category',
    templateUrl: './sub-category.component.html',
    styleUrls: ['./sub-category.component.css'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        CategoryBreadcrumbComponent,
    ],

})

export class SubCategoryComponent implements OnInit, OnChanges {
  firstObj: number = this.appConstants.firstObj;
  cdnImagePath: string = environment.cdnImagePath;
  vehicleName: string = '';
  product_key: any;
  categoryKeyChangeSub: Subscription;
  isVehicleSearch: boolean = false;
  allProducts: any[] = []
  promotionDescription: string;
  productVariation:any[] = [];
  noMoreProducts: boolean = false;
  noMoreCategory: boolean = false;

  @Input() category_key: number = 0;
  @Input() subCategories: any[]  = [];
  @Input() subProducts: any[] = []
  @Input() catProductPromotion : any;
  @Input() universalProducts: any[] = [];
  @Input() name: string;
  @Input() limitSubCategories: number;
  @Input() limitSubProducts: number;
  @Input() hideProductsLoadMore: boolean;
  @Input() hideCategoriesLoadMore: boolean;
  @Output() refreshPageEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() subCategoriesLoadMore: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() subProductsLoadMore: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('loadMoreRefCategory') loadMoreRef1: ElementRef;
  @ViewChild('loadMoreRefProduct') loadMoreRef2: ElementRef;

  constructor(
    private appConstants: AppConstants,
    private router: Router,
    private vehicleSearchService: SearchByVehicleService,
    private commonPageService: CommanpageService
  ) {}

  ngOnInit(): void {
    this.category_key = +localStorage.getItem('selectedcategorykey');
    this.isVehicleSearch = this.vehicleSearchService.isSearchVehicleExists();
    this.vehicleName = !this.isVehicleSearch ? '' : this.vehicleSearchService.getVechicleName();
    this.productVariation = JSON.parse(localStorage.getItem('productVariation'));
    console.log("Product Variation :", this.productVariation);
  }

  ngAfterViewInit(): void {
    this.commonPageService.initIntersectionObserver(this.loadMoreRef1, this.onClickCategoryLoadMore.bind(this));
    this.commonPageService.initIntersectionObserver(this.loadMoreRef2, this.onClickProductLoadMore.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
   if (changes?.['subCategories'] || changes?.['subProducts']) {
    if (this.universalProducts.length > 0 && this.subProducts.length > 0) {
      this.combineAllProducts();
    }
    else if (this.universalProducts.length > 0) {
      this.allProducts = [...this.universalProducts];
    }
    else if(this.subProducts.length > 0){
      this.allProducts = [...this.subProducts];
    }
    else{
      this.allProducts = []
    }
   }
  }

  combineAllProducts(){
    this.allProducts = [...this.universalProducts , ...this.subProducts];
  }

  productRedirection(product: {product_key: number , name: string, product_variations:any}) {
    localStorage.removeItem('selectedcategorykey');
    const productName = this.commonPageService.setUrlNameField(product.name);
    if (product.product_variations.length > 1) {
      this.router.navigate(['/' + productName, 'p' + product.product_key]);
    } else
      this.router.navigate(['/' + productName, product.product_variations[0].sku, 'p' + product.product_key]);
  }

  categoryRedirection(category_key: number,category_name: string){
    localStorage.setItem('selectedcategorykey',String(category_key));
    this.router.navigate(['/'+category_name.replaceAll(' ','-').toLowerCase(),'c'+category_key]);
  }

  onClearVehicleSearch(){
    this.vehicleSearchService.clearVehicleSearch();
    this.refreshPageEvent.emit(true);
  }

  onClickCategoryLoadMore(){
    this.subCategoriesLoadMore.emit(true);
    this.noMoreCategory=true;
  }

  onClickProductLoadMore(){
    this.subProductsLoadMore.emit(true);
    this.noMoreProducts=true;
  }  
}
