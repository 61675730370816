import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { searchByVehicleDropDownPostDataInterface, searchByVehiclePostDataInterface } from '../interfaces/request-interfaces/vehicle-search.interface';
import { CommunicationService } from './communication.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class SearchByVehicleService {
  private endpoint: string = environment.apiUrl;
  private makeresponseData: any;
  private modelresponseData: any;
  private yearresponseData:any;
  private submodels1responseData:any;
  private submodels2responseData:any;
  private submodels3responseData:any;

  private currentMakedata:any;
  
  
  constructor(
    private http: HttpClient,
    private comm : CommunicationService,
    private router: Router,
  ) {}

  getSearchByVehicleResults(data:searchByVehiclePostDataInterface): Observable<any>{
    return this.http.post<any>(this.endpoint+'search-by-vehicle-results',data);
  }


  getDropDownDataSearchByVechicles(data: searchByVehicleDropDownPostDataInterface):Observable<any>{
    return this.http.post(this.endpoint+'search-by-vehicle',data);
  }

  clearVehicleSearch(){
    this.comm.vehiclenameSubject.next('');
    localStorage.removeItem('vehiclename');
    if (localStorage.getItem('vehiclesearchForm') != undefined) {
      localStorage.removeItem('vehiclesearchForm');
      if (this.router.url == '/vehicle-search') {
        this.router.navigate(['/']);
      }
      
    }
    localStorage.removeItem('vehiclesearchData');
  }

  isSearchVehicleExists(): boolean{
    let vehicleName = localStorage.getItem('vehiclename') || null;
    return !vehicleName ? false : true;  
  }

  getVechicleName(): string{
    const vehicleName = localStorage.getItem('vehiclename') || null;
    return !vehicleName ? null : vehicleName;
  }

  setMakeResponseData(data: any): void {
    localStorage.setItem('makeData', JSON.stringify(data));
    this.makeresponseData = data;
  }  

  setModelResponseData(data: any): void {
    localStorage.setItem('modelData', JSON.stringify(data));
    this.modelresponseData = data;
  }  

  setYearResponseData(data: any): void {
    localStorage.setItem('yearData', JSON.stringify(data));
    this.yearresponseData = data;
  } 

  setSubmodels1ResponseData(data: any): void {
    localStorage.setItem('submodels1', JSON.stringify(data));
    this.submodels1responseData = data;
  }

  setSubmodels2ResponseData(data: any): void {
    localStorage.setItem('submodels2', JSON.stringify(data));
    this.submodels2responseData = data;
  }

  setSubmodels3ResponseData(data: any): void {
    localStorage.setItem('submodels3', JSON.stringify(data));
    this.submodels3responseData = data;
  }

  getMakeResponseData(): any {
    return this.makeresponseData !=null ? this.makeresponseData : JSON.parse(localStorage.getItem("makeData"));
  }

  getModelResponseData(): any {
    return this.modelresponseData !=null ? this.modelresponseData : JSON.parse(localStorage.getItem("modelData"));
  }

  getYearResponseData(): any {
    return this.yearresponseData !=null ? this.yearresponseData : JSON.parse(localStorage.getItem("yearData"));
  }

  getSubmodels1ResponseData(): any {
    return this.submodels1responseData !=null ? this.submodels1responseData : JSON.parse(localStorage.getItem("submodels1"));
  }

  getSubmodels2ResponseData(): any {
    return this.submodels2responseData !=null ? this.submodels2responseData : JSON.parse(localStorage.getItem("submodels2"));
  }

  getSubmodels3ResponseData(): any {
    return this.submodels3responseData !=null ? this.submodels3responseData : JSON.parse(localStorage.getItem("submodels3"));
  }


}
