import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  Renderer2,
  ViewChildren,
  QueryList,
  OnDestroy} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';

import { environment } from 'src/environments/environment.prod';

import { debounceTime, Subject, switchMap } from 'rxjs';

import { AppConstants } from 'src/app/app.constants';

import { CommunicationService } from 'src/app/service/communication.service';
import { HeaderService } from 'src/app/service/header.service';
import { ThemePermissionMatrixService } from 'src/app/service/theme-permission-matrix.service';
import { ProductService } from 'src/app/service/product.service';
import { CommanpageService } from 'src/app/service/commanpages.service';
import { LoginService } from 'src/app/service/login.service';

import { SearchByVehicleComponent } from 'src/app/templates/search-by-vehicle/search-by-vehicle.component';

import { HeadersDataInterface, RowData } from 'src/app/interfaces/header.interface';
import { ProductVariationSearchInterface } from 'src/app/interfaces/product.interface';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgStyle, NgClass, NgFor, NgIf } from '@angular/common';
@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    styleUrls: ['./header-nav.component.css'],
    standalone: true,
    imports: [
        RouterLink,
        NgStyle,
        NgClass,
        NgFor,
        NgIf,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        FormsModule,
    ],
})
export class HeaderNavComponent implements OnInit, AfterViewInit, OnDestroy{
  firstObj: number = this.appConstants.firstObj;
  storeImagePath: string = environment.storeImagePath;
  product_variation_key = null;
  product_key: number = null;
  username:string = localStorage.getItem('Username');
  vehicleName: string = '';
  headerdata: HeadersDataInterface = {
    websiteHeader: [],
    firstRow: [],
    secondRow: [],
    thirdRow: []
  };
  isNavbarCollapsed: boolean = true;

  subCategoriesData: any = [];

  category_key: number;

  cartItems: number = 0;

  displayDropDownMenuSearch: boolean = false;

  headerSearchName: boolean = false;
  headerSearchHash : boolean = false;
  //navBarElementsArray: Array<ElementRef>;
  navBarElementsArray: any[] = [];
  applyCustomClass: boolean = false;
  applyCustomVehicleClass: boolean = false;

  scrollStrategy: ScrollStrategy;
  openMobileViewSubCat: Array<boolean> = [];
  searchValueByPartName: string = '';
  searchValueByPartHash: string = '';

  private searchSubject = new Subject<string>();
  private partHashSubject = new Subject<string>();

  searchResultArrayByPartName: any[] = [];
  searchResultArrayByPartHash: any[] = [];

  isProductPage: boolean = false;


  @Input() dataFromController1: string;

  showLogOutDesktop:boolean;
  widnowResizeEvent:any;

  @ViewChild('DropdownMenuSearch') DropdownSearchMenuElementRef: ElementRef;
  @ViewChild('SearchIcon') SearchIcon : ElementRef;

  @ViewChild('ListsearchByPartName') ListElementSearchByPartNameRef :  ElementRef;
  @ViewChild('ListsearchByPartHash') ListElementSearchByPartHashRef: ElementRef;

  @ViewChild('DivSearchByPartName') DivElementSearchByPartNameRef: ElementRef;
  @ViewChild('DivSearchByPartHash') DivElementSearchByPartHashRef : ElementRef

  @ViewChildren('navBarElements') navBarElements: QueryList<ElementRef>;
  @ViewChildren('dropdownItemsNavBar') dropdownItemsNavBar: QueryList<ElementRef>;

  menuData: any = {};
  menuData1: any = {};
  menuData2: any = {};
  constructor(
    private appConstants: AppConstants,
    private headerHttp: HeaderService,
    private router: Router,
    private renderer: Renderer2,
    private comm : CommunicationService,
    public dialog: MatDialog,
    private readonly sso: ScrollStrategyOptions,
    private themepermission:ThemePermissionMatrixService,
    private productService: ProductService,
    private commonService: CommanpageService,
    private route: ActivatedRoute,
    private loginService: LoginService
    ) {
      this.scrollStrategy = this.sso.noop();
      this.vehiclenameSubject();
      this.searchChangeByPartName();
      this.searchChangeByPartHash();
      this.widnowResizeEvent = window.addEventListener('resize',()=>{
        this.showLogOutDesktop = window.innerWidth > 575;
      })
    }

  ngOnInit(): void {
    this.showLogOutDesktop = window.innerWidth > 575;
    this.product_key = +localStorage.getItem('selectedproductkey') || null;
    this.product_variation_key = +localStorage.getItem('selectedproductvariationkey') || null;
    this.headerHttp.headerList().subscribe(data => {
      this.headerdata = data[this.firstObj].data;
    });  
    
    this.themepermission.homepageSettings().subscribe(data => {
      this.menuData = data[this.firstObj].data?.menus || {};
    });   
    
    this.vehicleName = localStorage.getItem('vehiclename') || '';   
    this.getorderitems();
    this.username = localStorage.getItem('Username');   
    this.route.params.subscribe(
      {
        next:(params: Params) =>
        {

          let id: string = params['id'];
          if(id){
            const pattern: boolean = /^[0-9]*$/.test(id.slice(1,id.length));
            if (id.startsWith('p') && id.length > 0 && pattern){
              this.isProductPage = true;
            }
            else{
              this.isProductPage = false;
            }
          }

        },
      }
    )

  }

  goToLogin() {
    const currentUrl = this.router.url;
    localStorage.setItem('redirectAfterLogin', currentUrl);
    this.router.navigate(['/customer-login']);
  }
  
  onClickMobileUser(){
    const isLoggedIn = this.loginService.isUserLoggedIn();
    if(isLoggedIn){
      this.router.navigate(['/edit-account']);
    }else{
      this.router.navigate(['/customer-login']);
    }
  }

  vehiclenameSubject(){
    this.comm.vehiclenameSubject.subscribe(
      {
        next:(name) =>
        {
            this.vehicleName = name;
        },
        error:(error: Error) =>
        {
          this.vehicleName = '';
          console.error('Custom Error: Unexpected Error while subscribing to vehicle Name', error);
        },
      }
    );
  }

  ngAfterViewInit(): void {
    document.addEventListener('click',(event: Event)=>{
      if(
        (!this.DropdownSearchMenuElementRef.nativeElement.contains(event.target))
          &&
        (!this.SearchIcon.nativeElement.contains(event.target))
        ){
        this.displayDropDownMenuSearch = false;
        }
      else if(
        (this.DropdownSearchMenuElementRef.nativeElement.contains(event.target))
          &&
        ((this.ListElementSearchByPartNameRef.nativeElement.contains(event.target))
          ||
         (this,this.ListElementSearchByPartHashRef.nativeElement.contains(event.target))
        )
        ){
        this.displayDropDownMenuSearch =  false;
      }

      if(this.ListElementSearchByPartNameRef.nativeElement.contains(event.target)){
        this.headerSearchName = true
      }
      else if(!this.DivElementSearchByPartNameRef.nativeElement.contains(event.target)){
        this.headerSearchName = false
        this.searchValueByPartName = '';
      }

      if(this.ListElementSearchByPartHashRef.nativeElement.contains(event.target)){
        this.headerSearchHash = true;
      }
      else if (!this.DivElementSearchByPartHashRef.nativeElement.contains(event.target)) {
        this.headerSearchHash = false;
        this.searchValueByPartHash = '';
      }

    });

    this.navBarElements.changes.subscribe(
      (elements) => {
        this.navBarElementsArray = [];
        elements.toArray().forEach(element => {
          this.navBarElementsArray.push(element);
        })
      }
    );

  }

  onClickSearchIcon() {
    this.displayDropDownMenuSearch = !this.displayDropDownMenuSearch;
  }
  boolSearchDivName(){
    this.headerSearchName = true;
  }

  boolSearchDivHash(){
    this.headerSearchHash = true;
  }

  displaySearchDivbyPartName() : string {
    return this.headerSearchName ? 'block' : 'none';
  }

  displaySearchDivbyPartHash() : string {
    return this.headerSearchHash ? 'block' : 'none';
  }

  getSubCategories(){
    this.headerHttp.headerNavCategoriesSubCategories().subscribe(
      {
        next:(response) =>
        {
          if (response[this.firstObj]?.success == true) {
            this.subCategoriesData = response[this.firstObj]?.data;
          }
          else{
            this.subCategoriesData = [];
          }
        },
        error:(error: Error) =>
        {
          console.error('Custom Error: Unexpected Error while fetching Categories-Subcategories dropdown data',error);
        },
      })
  }

  onClickmobileCategories(header: RowData){
    let elementref = this.dropdownItemsNavBar.find(element => element.nativeElement.id == header.menu_name) || null;
    if (!!elementref) {
      
      const hasClass = elementref.nativeElement.classList.contains('displaySubMenus');
      if (hasClass) {
        this.renderer.removeClass(elementref.nativeElement, 'displaySubMenus');
        this.renderer.addClass(elementref.nativeElement, 'nodisplaySubMenus');
        this.categoriesRedirection(header,'mobile');
      }else{
        this.renderer.removeClass(elementref.nativeElement, 'nodisplaySubMenus');
        this.renderer.addClass(elementref.nativeElement, 'displaySubMenus');
  
      }
    }
    else{
      this.categoriesRedirection(header,'mobile');
    }
  }

  categoriesRedirection(header: RowData, view: 'mobile' | 'desktop' = 'desktop') : void {

    if (view == 'mobile') {
      this.toggleNavbar();
    }
    if(header.menu_link == '#vehicleSearch'){
      this.openVechicleSearchPopup()
      return;
    }

    const categoryIdMatch = header.menu_link.match(/\/c(\d+)/);
    this.category_key = parseInt(categoryIdMatch[1], 10);

    if(categoryIdMatch && categoryIdMatch[1] ) {
      if(this.category_key){
        localStorage.setItem('selectedcategorykey',String(this.category_key));
        if (this.router.url == '/category-details') {
          this.comm.categoryKeyChange.next(null);
        }
      }
      this.router.navigate([header.menu_link]);
    }
  } 

  openVechicleSearchPopup(): void {
    this.dialog.open(SearchByVehicleComponent, {
    });
  }

  onMouseLeaveDropdownListElement(event: MouseEvent,element: HTMLUListElement,name: string){
    for(let i = 0; i < this.navBarElementsArray.length; i++){
        this.renderer.removeClass(this.navBarElementsArray[i].nativeElement,'custom-navBar-Hover');
    }

    if (name != 'Search By Vehicle ') {
      this.renderer.removeClass(event.target,'open');
      this.renderer.setStyle(element,'display','none');
    }
  }

  onMouseHoverDropdownListElement(event:MouseEvent,element: HTMLUListElement,name: string){

    for(let i = 0; i < this.navBarElementsArray.length; i++){
      if (this.navBarElementsArray[i].nativeElement == event.target) {
        this.renderer.addClass(event.target,'custom-navBar-Hover');
      }
    }

    if (name != 'Search By Vehicle ') {  
      this.renderer.addClass(event.target,'open');   
      this.renderer.setStyle(element,'display','block');
    }
  }

  subCategoryRedirection(event: Event,element: HTMLUListElement,category_key: number, view: 'mobile'| 'desktop' = 'desktop'){
    if (view == 'mobile') {
      this.toggleNavbar();
    }

    localStorage.setItem('selectedcategorykey',String(category_key));
    this.comm.categoryKeyChange.next(null);

    this.renderer.removeClass(event.target,'open');
    this.renderer.setStyle(element,'display','none');

    for(let i = 0; i < this.navBarElementsArray.length; i++){
      this.renderer.removeClass(this.navBarElementsArray[i].nativeElement,'custom-navBar-Hover');
    }
  }

    toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }
  //HTML AND CSS changed programatically

  displayName(menu_name: string){
    if (menu_name == 'Search By Vehicle ') {
      if (!!this.vehicleName) {
        return this.vehicleName
      }
      else{
        return menu_name;
      }
    }
    else{
      return menu_name;
    }
  }
  vehicleImage(){
    return `${this.displayName('Search By Vehicle ')} <img src='../../../assets/images/mcLeod_header_car_ico.png' loading="eager" alt='car-logo' width='34' height='12'>`;
  }
  applySubCategoryCustomClass(menu_name: string){
    if(menu_name !== 'Search By Vehicle '){
      this.applyCustomClass = true;
    }
  }

  removeSubCategoryCustomClass(menu_name:string){
    if (menu_name !='Search By Vehicle '){
      this.applyCustomClass = false;
    }
  }

  applyCustomVehicleClassMethod(menu_name:string){
    if (menu_name == 'Search By Vehicle ') {
      this.applyCustomVehicleClass = true;
    }
  }
  removeCustomVehicleClassMethod(menu_name: string){
    if (menu_name == 'Search By Vehicle ') {
      this.applyCustomVehicleClass = false;
    }
  }

  getorderitems(): void{
    this.cartItems = +localStorage.getItem('ItemCount');
    this.comm.itemCount.subscribe(()=>{
      this.cartItems = +localStorage.getItem('ItemCount');
    });
  }

  onInputvalueChangeByPartName(){
    this.searchSubject.next(this.searchValueByPartName);
  }

  onInputvalueChangeByPartHash(){
    this.partHashSubject.next(this.searchValueByPartHash);
  }

  searchChangeByPartName(){
    this.searchSubject.pipe(
      debounceTime(300), // Adjust the debounce time as needed
      switchMap(value => this.productService.productByPartNameSearchResults(value))
    )
    .subscribe(
      {
        next:(resultArray: ProductVariationSearchInterface[]) => 
        {
          this.searchResultArrayByPartName = [];
          if (resultArray?.length > 0) {
            this.searchResultArrayByPartName = resultArray;
          }
        },
        error:(error: Error) => 
        {
          // console.error('Custom Error: Unexpected Error while fetching data from products SearchByPartName', error);
        }
      }
    );
  }

  searchChangeByPartHash(){
    // Handle search for part hash
    this.partHashSubject.pipe(
      debounceTime(300), // Adjust the debounce time as needed
      switchMap(value => this.productService.productByPartHashSearchResults(value))
    )
    .subscribe(
      {
        next:(resultArray: ProductVariationSearchInterface[]) => 
        {
          this.searchResultArrayByPartHash = [];
          if (resultArray?.length > 0) {
            this.searchResultArrayByPartHash = resultArray;
          }
        },
        error:(error: Error) => 
        {
          // console.log('Custom Error: Unexpected Error while fetching data from product-variations searchByPartHash', error);
        }
      }
    );

  }

  onClickSearchByPartNameResult(productKey: number,productName: string): void{
    let name: string = this.commonService.setUrlNameField(productName);
    name = name.toLowerCase();
    localStorage.removeItem('selectedproductvariationkey');
    this.product_variation_key = null;
    if(this.isProductPage && this.product_key != productKey){
      localStorage.setItem('selectedproductkey',String(productKey));
      this.product_key = productKey;
      this.comm.searchByPartNameProductKeyChange.next(null);
    }
    else if(this.isProductPage && this.product_key == productKey){
    }
    else if(!this.isProductPage && this.product_key != productKey){
      localStorage.setItem('selectedproductkey',String(productKey));
      this.product_key = productKey;
    }
    this.router.navigate(['/'+name,'p'+productKey]);
    this.comm.serchByPartHashKeyChange.next(null);
    this.headerSearchName = false;
    this.searchValueByPartName = ''
  }

  onClickSearchByPartHashResult(productKey: number,productVariationKey: number,productName: string,sku: string) : void{
    let name = this.commonService.setUrlNameField(productName);
    name = name.toLowerCase();
    const skuName = this.commonService.setUrlNameField(sku)
    if (sku.includes(' ')) {
      localStorage.setItem('replaceDashSku', String(true));
    }
    if(!this.isProductPage) {
      localStorage.setItem('selectedproductkey',String(productKey));
      if(this.product_key != productKey) {
        this.product_key = productKey;
        localStorage.setItem('selectedproductvariationkey',String(productVariationKey));
        this.product_variation_key = productVariationKey;
        this.router.navigate(['/'+name,skuName,'p'+productKey])
      }
      else if(this.product_key == productKey && this.product_variation_key != productVariationKey){
        localStorage.setItem('selectedproductvariationkey',String(productVariationKey));
        this.product_variation_key = productVariationKey;
        this.router.navigate(['/'+name,skuName,'p'+productKey])
      }
      else if(this.product_key == productKey && this.product_variation_key == productVariationKey){
        this.router.navigate(['/'+name,skuName,'p'+productKey])
      }
    }
    else if (this.isProductPage){
      if(this.product_key != productKey){
        localStorage.setItem('selectedproductkey',String(productKey));
        this.product_key = productKey;
        localStorage.setItem('selectedproductvariationkey', String(productVariationKey));
        this.product_variation_key = productVariationKey;
        this.comm.searchByPartNameProductKeyChange.next(null);
      }
      else if(this.product_key == productKey && this.product_variation_key != productVariationKey){
        localStorage.setItem('selectedproductvariationkey',String(productVariationKey));
        this.product_variation_key = productVariationKey;
        this.comm.serchByPartHashKeyChange.next(null);
      }
      else if(this.product_key == productKey && this.product_variation_key == productVariationKey){
        return;
      }
    }

    this.headerSearchHash = false;
    this.searchValueByPartHash = '';
  }

  activeDropdownIndex: number = -1;
  toggleDropdown(tabIndex: number) {
    if (this.activeDropdownIndex !== tabIndex) {
      this.activeDropdownIndex = tabIndex;
    } else {
      this.activeDropdownIndex = -1;
    }
  }
  
  ngOnDestroy(): void {
    window.removeEventListener('resize',this.widnowResizeEvent);
      
  }


}
