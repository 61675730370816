import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppConstants } from 'src/app/app.constants';
import { CategoriesDataInterface, CategoryByIDPostDataInterface, CategoryFilterPostDataInterface, Common } from 'src/app/interfaces/category.interface';

import { CategoryService } from 'src/app/service/category.service';
import { ActivatedRoute,  } from '@angular/router';
import { CommanpageService } from 'src/app/service/commanpages.service';
import { SubscribeComponent } from '../../../subscribe/subscribe.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { CategoryTopDetailsComponent } from './category-top-details/category-top-details.component';
import { CategorySkeletonComponent } from '../../../pages-skeleton/component/category-skeleton/category-skeleton.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-category-details',
    templateUrl: './category-details.component.html',
    styleUrls: ['./category-details.component.css'],
    standalone: true,
    imports: [
      NgIf,
      CategorySkeletonComponent,
      CategoryTopDetailsComponent,
      SubCategoryComponent,
      SubscribeComponent,
      ]
})
export class CategoryDetailsComponent implements OnInit,OnDestroy {
  categoriesdetail: CategoriesDataInterface;
  categoriesCachedData: any= [] ;
  category_key: number = 0;
  firstObj: number = this.appConstants.firstObj;
  categoryKeyChangeSub: Subscription;
  isLoading: boolean = false;
  productPromotion: string;

  subCategories: any[] = [];
  subCategoriesCount: number = null;
  subProducts: any[] = []
  subProductsCount: number = null;
  topCategoryData: any;
  universalProducts: any[]= []
  universalProductsCount: number = null;
  isProduct: boolean = null;
  categoryFilters: {[Number:string]:string} = null;
  name: string;

  make_key: number;
  model_key: number;
  year: string | number;
  filter_key: string;

  offsetSubCategoris: number = 0;
  limitSubCategories: number = 7;
  offsetSubProducts: number = 0;
  limitSubProducts: number = 7;
  offsettUniversalProducts: number = 0;
  limitUniversalProducts: number = 16;
  hideCategoriesLoadMore: boolean;
  hideProductsLoadMore: boolean;
  productVariation: any[]=[];
  //dab-template

  constructor(
    private appConstants: AppConstants,
    private commonService: CommanpageService,
    private categoryHttp: CategoryService,
    private route: ActivatedRoute
    ){}

  ngOnInit(): void {
    this.route.params.subscribe({
      next:async () =>
        {
          const categoryKey = +localStorage.getItem('selectedcategorykey');
          if (this.category_key != categoryKey) {
            this.resetPagination();
            this.getMakeModelYearData();
            this.isLoading = true;
            if (!!categoryKey) {
              this.category_key = +localStorage.getItem('selectedcategorykey');
              await this.getHomeCategories();
              if (!this.categoryFilters) this.fetchCategoriesFilterData();
            }
          }

      },
    })
  }

  resetPagination(): void {
  this.offsetSubCategoris = 0;
  this.offsetSubProducts = 0;
  this.offsettUniversalProducts = 0;
  this.hideCategoriesLoadMore = false;
  this.hideProductsLoadMore = false;
  this.subCategories = [];
  this.subProducts = [];
  this.universalProducts = [];
}

  async refreshCategoryPage(){
    this.make_key = this.model_key = null;
    this.year = "";
    this.isLoading = true;
    const categoryKey = +localStorage.getItem('selectedcategorykey');
    if (!!categoryKey) {
      this.category_key = +localStorage.getItem('selectedcategorykey');
      await this.getHomeCategories();
      window.scrollTo({top: 0, behavior: 'smooth'})
      if (!this.categoryFilters) {
        this.fetchCategoriesFilterData();
      }
    }
  }

  getMakeModelYearData(){
    const mmyData = JSON.parse(localStorage.getItem('vehiclesearchForm')) || null;
    if (mmyData) {
      this.make_key = mmyData?.['select_make'] || null;
      this.model_key = mmyData?.['select_model'] || null;
      this.year = mmyData?.['select_year'] || '';
    }
  }

  async getHomeCategories(
    offsetSubCategories: number = 0 ,
    offsetSubProducts: number = 0 ,
    offsetUniversalProducts: number = 0
  ) : Promise<void>{
    return new Promise<void>((resolve,reject)=>{

      const data: CategoryByIDPostDataInterface = {
        category_key: this.category_key,
        make_key: this.make_key || null,
        model_key: this.model_key || null,
        year: this.year || '',
        filter_key: this.filter_key || '',
        category_offset: offsetSubCategories,
        product_offset: offsetSubProducts,
        univseral_product_offset: offsetUniversalProducts
      }
      this.categoryHttp.singlecategory(data).subscribe(
        {
          next: (response:Common<CategoriesDataInterface>) =>
          {
            if (response[this.firstObj]?.success == true) {

              this.name = response[this.firstObj]?.data?.categories[this.firstObj]?.name
              this.categoriesdetail = response[this.firstObj]?.data.categories;
              this.topCategoryData = response[this.firstObj]?.data.categories;

              //when offset logic is implemented on backend
              if (offsetSubCategories > 0) {
                this.subCategories = this.subCategories.concat(this.categoriesdetail[this.firstObj]?.child_cats || []);
                if(this.categoriesdetail[this.firstObj]?.child_cats?.length < 8){
                  this.hideCategoriesLoadMore = true;
                }
              }
              else if(offsetSubProducts > 0){
                this.subProducts = this.subProducts.concat(this.categoriesdetail[this.firstObj]?.product_categories || [])
                if(this.categoriesdetail[this.firstObj]?.product_categories < 8){
                  this.hideProductsLoadMore = true
                }
              }
              else if(offsetUniversalProducts > 0){
                this.universalProducts = this.subCategories.concat(response[this.firstObj]?.data?.universalProducts || []);
              }
              else{
                this.subCategories = this.categoriesdetail[this.firstObj]?.child_cats ||  []; //this.categoriesdetail[this.firstObj]?.product_categories ||
                this.subCategoriesCount = this.subCategories.length || 0;
                this.subProducts = this.categoriesdetail[this.firstObj]?.product_categories || [];
                
                const variationsByIndex: any[] = [];
                this.subProducts.forEach((subProduct, index) => {
                  const variations = subProduct.products.flatMap(product => product.product_variations);
                  variationsByIndex[index] = variations;
                });
                this.productVariation = variationsByIndex
                localStorage.setItem('productVariation',JSON.stringify(this.productVariation));

                this.subProductsCount = this.subProducts.length || 0;
                this.universalProducts = response[this.firstObj]?.data?.universalProducts || [];
                this.universalProductsCount = this.universalProducts.length || 0;
              }
              this.productPromotion = response[this.firstObj]?.data?.promotion;
              this.categoryFilters = response[this.firstObj]?.data?.filters;            ;
              this.isLoading = false;
              if (this.categoriesdetail[this.firstObj]?.product_categories) {
                this.isProduct = true
              }
              else{
                this.isProduct = false;
              }
              resolve();
            }
            else{
              this.commonService.pagenotfound();
              resolve();
            }

          },
          error: (error: Error) =>
          {
            //later log it into a separate file
            // console.error('Custom Error: Unexpected Error happened while fetching category details:', error);
            this.isLoading = false;
            this.commonService.pagenotfound();
            resolve();
          }
        });
    })

  }

  fetchCategoriesFilterData(){
    const data: CategoryFilterPostDataInterface = {
      category_key: this.category_key,
      parent_category_key: null,
      filter_key: null
    }
    this.categoryHttp.categoryFilter(data).subscribe(
      {
        next:(resposne)=>
        {
          if (resposne[this.firstObj]?.success == true){
            const data = resposne[this.firstObj]?.data;
            if (data?.filters) {
              data?.filters?.length == 0 ? this.categoryFilters = null : this.categoryFilters = data?.filters;
            }
          }
        },
      }
    )
  }

  async loadMoreSubCategories(val: boolean) {
  this.offsetSubCategoris += this.limitSubCategories;
  this.getMakeModelYearData();
  await this.getHomeCategories(this.offsetSubCategoris);
  if (this.subCategories.length < this.limitSubCategories) {
    this.hideCategoriesLoadMore = true;
  }
}

 async laodMoreSubProducts(val: boolean) {
  this.offsetSubProducts += this.limitSubProducts;
  this.getMakeModelYearData();
  await this.getHomeCategories(0, this.offsetSubProducts, 0);
  if (this.subProducts.length < this.limitSubProducts) {
    this.hideProductsLoadMore = true;
  }
}

  async applyFilter(filterKey: string){
    this.filter_key = filterKey;
    await this.getHomeCategories();

  }

  async applyShowAll(){
    this.filter_key = '';
    await this.getHomeCategories();
  }


  ngOnDestroy(): void {
    if (!!this.categoryKeyChangeSub) {
      this.categoryKeyChangeSub.unsubscribe();
    }
  }
}
