import { Component } from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.css'],
    standalone: true
})
export class SpinnerComponent {
  constructor() {
    window.scrollTo({top: 0})
  }
}
