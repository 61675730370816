<div class="final_product_tabs_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="tab-content tab_section" style="display: block;">
                        <div class="tab-pane fade prod_instr mb-20 active in" id="fp_tab7">
                            <div class="item_table_block table-responsive">
                                <table class="item_table">
                                    <thead>
                                        <tr>
                                            <th width="10%">SKU#</th>
                                            <th width="60%">FILE NAME</th>
                                            <th width="10%">DATE</th>
                                            <th width="10%" class="hidden-xs"></th>
                                            <th width="10%" class="hidden-xs"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let instruction of instructionsData">
                                            <td>{{instruction.sku}}</td>
                                            <td>
                                                {{instruction.file_name}}
                                            </td>
                                            <td>
                                                {{instruction.update_date | date:'MM-dd-yyyy'}}
                                            </td>
                                            <td class="hidden-xs">
                                                <a class="btn btn-primary" [href]="domainImageBasePath+'installation_instruction-instruction_file-107-v7.pdf'" download="">
              Download
            </a>
                                            </td>
                                            <td class="hidden-xs">
                                                <a *ngIf="isVideoAvailable" href="" class="btn btn-primary product_vid bmd-modalButton" style="font-size:14px" data-toggle="modal" data-target="#myModal-video">
              View Video
            </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>