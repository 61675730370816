import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Observable , of, throwError} from 'rxjs';
import { tap, shareReplay, catchError } from 'rxjs/operators';
import { homeComponentSetting } from '../interfaces/homepagesettings.interface';
import { Subject } from 'rxjs';
import { response } from 'express';

@Injectable({
  providedIn: 'root'
})
export class ThemePermissionMatrixService {
  jsonData:any;
  private endpoint: string = environment.apiUrl;
  private cachedData$: Observable<any>;
  
  routerOutletHomeSetting: homeComponentSetting<string> = null;
  routerOutletHomeSettingEmitter: Subject<null> = new Subject<null>;

  constructor(private http: HttpClient){}

  /*getJsonData(): Observable<any> {
    this.http.get('assets/homepagesetting.json').subscribe(
      (data) => {
        this.jsonData = data;
        console.log('JSON Data:', this.jsonData.data);
      },
      (error) => {
        console.error('Error fetching JSON data:', error);
      }
    );
    return this.jsonData;
  }*/

  getJsonData(): Observable<any> {
    return this.http.get('assets/homepagesetting.json').pipe(
      tap((response) => {
        this.jsonData = response;
        console.log('JSON Data:', this.jsonData); 
      }),
      catchError((error) => {
        console.error('Error fetching JSON data:', error);
        return throwError(error); 
      })
    );   
  }

  homepageSettings(): Observable<any> {
    const storedData = this.getStoredHomeSettngData();
    if (storedData) {
      return of(storedData);
    }

    if (!this.cachedData$) {
      /*this.cachedData$ = this.getJsonData().pipe(
        tap(data => {                
          this.storeHomeSettingData(data);         
        }),
        shareReplay(1)
      ); */

      this.cachedData$ = this.http.get(this.endpoint+'homepage-settings').pipe(
        tap(data => {         
          this.storeHomeSettingData(data);
        }),
        shareReplay(1)
      );      
    }
    return this.cachedData$;
  }

  private storeHomeSettingData(data: any): void {
    localStorage.setItem('homeSettingData', JSON.stringify(data));
  }

  private getStoredHomeSettngData(): any | null {
    const data = localStorage.getItem('homeSettingData');
    return data ? JSON.parse(data) : null;
  }
}
