import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appLazyLoad]',
  standalone: true
})
export class LazyLoadDirective  {
  @Output() lazyLoad: EventEmitter<void> = new EventEmitter();

  private observer: IntersectionObserver;

  constructor(private el: ElementRef) {
   
  }

  ngOnInit() {
    if ('IntersectionObserver' in window) {  // Ensure browser supports IntersectionObserver
      this.observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            console.log(`🔄 Lazy loading triggered for:`, this.el.nativeElement);
            this.lazyLoad.emit();  // Emit event to load component
            this.observer.disconnect();  // Stop observing after it becomes visible
          }
        },
        { threshold: 0.2 } // Only triggers when 20% of the element is visible
      );

      this.observer.observe(this.el.nativeElement);  // Start observing the element
    } else {
      // Fallback if IntersectionObserver is not supported
      this.lazyLoad.emit();
    }
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}