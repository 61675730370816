import { DialogRef } from '@angular/cdk/dialog';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constants';
import { CommanpageService } from 'src/app/service/commanpages.service';
import { CommunicationService } from 'src/app/service/communication.service';
import { SearchByVehicleService } from 'src/app/service/search-by-vehicle.service';
import { NgIf, NgFor, KeyValuePipe } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { ClickOutsiteDirective } from '../../directive/click-outsite.directive';
import { SpinnerComponent } from '../spinner/spinner.component';
import { SortByValuePipe } from 'src/app/pipes/keyvaluenosort';


@Component({
    selector: 'app-search-by-vehicle',
    templateUrl: './search-by-vehicle.component.html',
    styleUrls: ['./search-by-vehicle.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [ClickOutsiteDirective, MatDialogModule, ReactiveFormsModule, FormsModule, NgxIntlTelInputModule, NgIf, NgFor, KeyValuePipe, SpinnerComponent,SortByValuePipe]
})
export class SearchByVehicleComponent implements OnInit{
  firstObj: number = this.appConstants.firstObj;
  initialData: {[key: string]: string} = {};
  vehicleName: string  = '';
  mmyDataExists: boolean = false;
  mmyDropDownData = {
    select_make: '',
    select_model: '',
    select_year: '',
    sub_model_1: '',
    sub_model_2: '',
    sub_model_3: ''
  }
  makes: {[key: string]:string} = {};
  selectedMakeKey: string = '';
  isMakeSelected: boolean = false;
  disableMake: boolean = false;
  
  models:{[key: string]:string} = {};
  selectedModelKey: string = '';
  isModelSelected: boolean = false;
  disableModel: boolean = true;
  
  years: {[key: string]:string} = {};
  selectedYearKey: string = '';
  isYearSelected: boolean = false;
  disableYear: boolean = true;
  
  submodels1: {[key: string]:string} = {};
  selectedSubmodel1Key: string = '';
  showSubmodel1:boolean = false;
  isSubmodel1selected: boolean = false;
  disableSubmodel1: boolean = false;
  
  submodels2: {[key: string]:string} = {};
  selectedSubmodel2Key: string = '';
  showSubmodel2:boolean = false;
  isSubmodel2selected: boolean = false;
  disableSubmodel2: boolean = false;
  
  submodels3: {[key: string]:string} = {};
  selectedSubmodel3Key: string = ''
  showSubmodel3:boolean = false;
  isSubmodel3selected: boolean = false;
  disableSubmodel3: boolean = false;

  disableSearchAgain: boolean = true;
  showSearchAgain: boolean = false;
  disableSearch: boolean = true;
  firstClickedOutside:boolean = true;
  homeSettings:any;
  showFeaturedLogo:any;

  isloading:boolean= false;
  vehicalsearchdata : any;

  MakeData:any;
  ModelData:any;
  YearData:any;
  Submodels1Data:any;
  Submodels2Data:any;
  Submodels3Data:any;

  dropDownData:any;

  getMakeData:any;
  getModelData:any;
  getYearsData:any;
  getSubModel1Data:any;
  getSubModel2Data:any;
  getSubModel3Data:any;

  makepopdata: any;
  makelocal: any;

  @ViewChild('searchbyvehicledialogref') searchbyvehicledialogref: ElementRef;
  
  constructor(
    private appConstants: AppConstants,
    private searchByVehicleService: SearchByVehicleService,
    private comm: CommunicationService,
    private dialogRef: DialogRef,
    private router: Router,
    private commonService: CommanpageService
  ){}

  ngOnInit(): void {
    this.homeSettings=JSON.parse(localStorage.getItem('homeSettingData'));
    this.showFeaturedLogo=this.homeSettings[0].data.body.featured_logo;
    (async()=>{
      await this.setMMYData();
    })();   
  }

  async setMMYData(){
    const mmyData = JSON.parse(localStorage.getItem('vehiclesearchForm')) || null; 
    if(mmyData){      
      this.showSearchAgain = true;
      this.isMakeSelected = true; 
      if(mmyData?.['select_make']){ 
        this.getMakeData= this.searchByVehicleService.getMakeResponseData();       
        this.selectedMakeKey = mmyData?.['select_make'] || '';
        this.makes = this.getMakeData?.makes;
        this.MakeData = this.getMakeData; 
        this.initialData = this.makes;
        this.mmyDropDownData['select_make'] = mmyData?.['select_make'] || '';
        this.disableMake = false;
      }
       if(mmyData?.['select_model']){
        this.getModelData= this.searchByVehicleService.getModelResponseData();        
        this.selectedModelKey = mmyData?.['select_model'] || '';
        this.models = this.getModelData?.models;
        this.ModelData = this.getModelData;
        this.models = this.ModelData?.models || {};
        this.mmyDropDownData['select_model'] = mmyData?.['select_model'] || '';
        this.disableModel = false;
       }
       if(mmyData?.['select_year']){
        this.getYearsData=this.searchByVehicleService.getYearResponseData();
        this.selectedYearKey = mmyData?.['select_year'] || '';
        this.years = this.getYearsData?.select_year; 
        this.YearData = this.getYearsData;
        this.years = this.YearData?.select_year || {};        
        this.mmyDropDownData['select_year'] = mmyData?.['select_year'] || '';
        this.disableYear = false;
        if (this.mmyDropDownData['select_year'] && mmyData?.['sub_model_1']) {
          this.showSubmodel1 = true;
        }
       }
      if(mmyData?.['sub_model_1']){        
        this.getSubModel1Data=this.searchByVehicleService.getSubmodels1ResponseData();            
        this.selectedSubmodel1Key = mmyData?.['sub_model_1'] || '';
        this.submodels1 = this.getSubModel1Data?.sub_model_1;     
        this.Submodels1Data = this.getSubModel1Data;       
        this.submodels1 = this.Submodels1Data?.sub_model_1 || {};        
        this.mmyDropDownData['sub_model_1'] = mmyData?.['sub_model_1'] || '';
        this.disableSubmodel1 = false;
        if (this.mmyDropDownData['sub_model_2']) {
          this.showSubmodel2 = true;
        }
      }
      if(mmyData?.['sub_model_2']){
        this.getSubModel2Data=this.searchByVehicleService.getSubmodels2ResponseData();
        this.selectedSubmodel2Key = mmyData?.['sub_model_2'] || '';
        this.submodels2 = this.getSubModel2Data?.sub_model_2;     
        this.Submodels2Data = this.getSubModel2Data;       
        this.submodels2 = this.Submodels2Data?.sub_model_2 || {};        
        this.mmyDropDownData['sub_model_2'] = mmyData?.['sub_model_2'] || '';
        this.disableSubmodel2 = false;
        if (this.mmyDropDownData['sub_model_3']) {
          this.showSubmodel3 = true;
        }
      }
      if(mmyData?.['sub_model_3']){
        this.getSubModel3Data=this.searchByVehicleService.getSubmodels3ResponseData();
        this.selectedSubmodel3Key = mmyData?.['sub_model_3'] || '';
        this.submodels3 = this.getSubModel3Data?.sub_model_3;     
        this.Submodels3Data = this.getSubModel3Data;       
        this.submodels3 = this.Submodels3Data?.sub_model_3 || {};        
        this.mmyDropDownData['sub_model_3'] = mmyData?.['sub_model_3'] || '';
        this.disableSubmodel3 = false;        
      }  
      
      //await this.fetchDropDownData();
      this.mmyDataExists = false;
      this.disableSearchAgain = false;
    }
    else{
      this.showSearchAgain = false;
      //await this.fetchDropDownData();
      this.getlocalMakedata();
      this.mmyDataExists = false;
      this.disableSearch = false;
    }

  }

  async fetchDropDownData(): Promise<void> {
    try {
      //const mmyData = JSON.parse(localStorage.getItem('vehiclesearchForm')) || null;
      const response = await this.searchByVehicleService.getDropDownDataSearchByVechicles(this.mmyDropDownData).toPromise();
      
      //this.disacardMMYData();
      if (response[this.firstObj]?.success === true) {
        const data = response[this.firstObj]?.data || null;        
          this.dropDownData = response[this.firstObj]?.data || null;
          if (this.dropDownData.makes) {
            this.searchByVehicleService.setMakeResponseData(this.dropDownData);
            this.MakeData = this.dropDownData;                       
            this.makes = this.MakeData?.makes || {}; 
            this.initialData = this.makes;            
          } else if (this.dropDownData.models) {
             this.searchByVehicleService.setModelResponseData(this.dropDownData);
             this.ModelData = this.dropDownData;             
             this.isMakeSelected = !!this.ModelData?.models;             
             this.models = this.ModelData?.models || {};             
          } else if (this.dropDownData.select_year){
             this.searchByVehicleService.setYearResponseData(this.dropDownData);
             this.YearData = this.dropDownData;
             this.isModelSelected = !!this.YearData?.select_year;
             this.years = this.YearData?.select_year || {};
          } else if (this.dropDownData.sub_model_1){
             this.searchByVehicleService.setSubmodels1ResponseData(this.dropDownData);
             this.Submodels1Data = this.dropDownData;
             this.isYearSelected = !!this.Submodels1Data?.sub_model_1;
             this.submodels1 = this.Submodels1Data?.sub_model_1 || {};
          } else if (this.dropDownData.sub_model_2){
             this.searchByVehicleService.setSubmodels2ResponseData(this.dropDownData);
             this.Submodels2Data = this.dropDownData;
             this.isSubmodel1selected = !!this.Submodels2Data?.sub_model_2;
             this.submodels2 = this.Submodels2Data?.sub_model_2 || {};
          }  else if (this.dropDownData.sub_model_3){
             this.searchByVehicleService.setSubmodels3ResponseData(this.dropDownData);
             this.Submodels3Data = this.dropDownData;
             this.isSubmodel2selected = !!this.Submodels3Data?.sub_model_3;
             this.submodels3 = this.Submodels3Data?.sub_model_3 || {};
          } 
      }
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  } 

  disacardMMYData(){
    this.makes = this.models = this.years = this.submodels1 = this.submodels2 = this.submodels3 = {};
  }

  async onSelectMake(){
    this.mmyDropDownData['select_make'] = this.selectedMakeKey || '';
    this.mmyDropDownData['select_model'] = '';
    this.mmyDropDownData['select_year'] = '';
    this.mmyDropDownData['sub_model_1'] = '';
    this.mmyDropDownData['sub_model_2'] = '';
    this.mmyDropDownData['sub_model_3'] = '';
    this.selectedModelKey = ''
    this.selectedYearKey = '';
    this.selectedSubmodel1Key = '';
    this.selectedSubmodel2Key = '';
    this.selectedSubmodel3Key = '';
    this.disableMake = true;
    this.disableModel = true;
    this.disableYear = true;
    this.showSubmodel1 =  false;
    this.showSubmodel2 = false;
    this.showSubmodel3 = false;
    this.disableSearch = this.disableSearchAgain = true;
    this.isloading = true;    
    
    if (this.selectedMakeKey != '' ) {
      await this.fetchDropDownData();      
    }
    else{
      this.models = this.years = {}
      this.disableModel = true;
    }
    this.disableMake = false;
    if (!this.commonService.isEmpty(this.models)){
      this.disableModel = false;
    }
    this.disableSearch = this.disableSearchAgain = false;  
    this.isloading = false;  
  }

  async onSelectModel(){
    this.mmyDropDownData['select_model']= this.selectedModelKey || '';
    this.mmyDropDownData['select_year'] = '';
    this.mmyDropDownData['sub_model_1'] = '';
    this.mmyDropDownData['sub_model_2'] = '';
    this.mmyDropDownData['sub_model_3'] = '';
    this.selectedYearKey = '';
    this.selectedSubmodel1Key = '';
    this.selectedSubmodel2Key = '';
    this.selectedSubmodel3Key = '';
    this.disableMake = true;
    this.disableModel = true;
    this.disableYear = true;
    this.showSubmodel1 = false;
    this.showSubmodel2 = false;
    this.showSubmodel3 = false;
    this.disableSearch = this.disableSearchAgain = true;
    this.isloading = true;

    await this.fetchDropDownData();
    this.disableMake = false;
    this.disableModel = false;
    if (!this.commonService.isEmpty(this.years)) {
      this.disableYear = false;
    }
    this.disableSearch = this.disableSearchAgain = false;
    this.isloading = false;
  }

  async onSelectYear(){
    this.mmyDropDownData['select_year'] = this.selectedYearKey || '';
    this.mmyDropDownData['sub_model_1'] = '';
    this.mmyDropDownData['sub_model_2'] = '';
    this.mmyDropDownData['sub_model_3'] = '';
    this.selectedSubmodel1Key = '';
    this.selectedSubmodel2Key = '';
    this.selectedSubmodel3Key = '';
    this.disableMake = true;
    this.disableModel = true;
    this.disableYear = true;
    this.showSubmodel1 = false;
    this.showSubmodel2 = false;
    this.showSubmodel2 = false;
    this.showSubmodel3 = false;
    this.disableSearch = this.disableSearchAgain = true;
    this.isloading = true;

    await this.fetchDropDownData();
    this.disableMake = false;
    this.disableModel = false;
    this.disableYear = false;
    this.disableSubmodel1 = false;
    if (!this.commonService.isEmpty(this.submodels1)) {
      this.showSubmodel1 = true;
    }
    this.disableSearch = this.disableSearchAgain = false;
    this.isloading = false;
  }

  async onSelectSubmodel1(){
    this.mmyDropDownData['sub_model_1'] = this.selectedSubmodel1Key || '';
    this.mmyDropDownData['sub_model_2'] = '';
    this.mmyDropDownData['sub_model_3'] = '';
    this.selectedSubmodel2Key = '';
    this.selectedSubmodel3Key = '';
    this.disableMake = true;
    this.disableModel = true;
    this.disableYear = true;
    this.disableSubmodel1 = true;
    this.disableSubmodel2 = false;
    this.disableSubmodel3 = false;
    this.showSubmodel2 = false;
    this.showSubmodel3  = false;
    this.disableSearch = this.disableSearchAgain = true;
    this.isloading = true;

    await this.fetchDropDownData();
    this.disableMake = false;
    this.disableModel = false;
    this.disableYear = false;
    this.disableSubmodel1 = false;
    if (!this.commonService.isEmpty(this.submodels2)) {
      this.showSubmodel2 = true;
    }
    this.disableSearch = this.disableSearchAgain = false;
    this.isloading = false;
  }

  async onSelectSubmodel2(){
    this.mmyDropDownData['sub_model_2'] = this.selectedSubmodel2Key || '';
    this.mmyDropDownData['sub_model_3'] = '';
    this.selectedSubmodel3Key = '';
    this.disableMake = true;
    this.disableModel = true;
    this.disableYear = true;
    this.disableSubmodel1 = true;
    this.disableSubmodel2 = true;
    this.disableSubmodel3 = false;
    this.showSubmodel3 = false;
    this.disableSearch = this.disableSearchAgain = true;
    this.isloading = true;

    await this.fetchDropDownData();
    this.disableMake = false;
    this.disableModel = false;
    this.disableYear = false;
    this.disableSubmodel1 = false;
    this.disableSubmodel2 = false;
    if (!this.commonService.isEmpty(this.submodels3)) {
      this.showSubmodel3 = true;
    }
    this.disableSearch = this.disableSearchAgain = false;
    this.isloading = false;
  }

  changeVehicleName(action = 'set'){
    if (action == 'set') {
      this.vehicleName = String(this.years[this.selectedYearKey] || '');
      if (!!this.makes?.[this.selectedMakeKey] ) {
        this.vehicleName = this.vehicleName.length > 0 ? this.vehicleName+ ' '+ this.makes[this.selectedMakeKey] : this.makes[this.selectedMakeKey];
      }
      if (!!this.models?.[this.selectedModelKey]) {
        this.vehicleName = this.vehicleName.length > 0 ? this.vehicleName+ ' '+ this.models[this.selectedModelKey]: this.models[this.selectedModelKey];
      }
      
      if (this.vehicleName.length > 0) {
        this.comm.vehiclenameSubject.next(this.vehicleName);
        localStorage.setItem('vehiclename', this.vehicleName);
      }else{
        localStorage.removeItem('vehiclename');
      }
    }      
    else if(action == 'remove'){
      this.vehicleName = '';
      this.comm.vehiclenameSubject.next(this.vehicleName);
      localStorage.removeItem('vehiclename');
    }
  }

  onSearchVehicle(){    
    localStorage.setItem('vehiclesearchForm',JSON.stringify(this.mmyDropDownData));
    this.changeVehicleName();
    this.dialogRef.close(SearchByVehicleComponent);
    this.router.navigate(['/vehicle-search']);
  }

  clickOutsideBox(isClickedOutside: boolean){
    if (isClickedOutside){
      if (!this.firstClickedOutside) {
        this.dialogRef.close(SearchByVehicleComponent);
      }
      this.firstClickedOutside = false;
    }
  }
  
  onClearVehicle(){
    this.disacardMMYData();
    this.disableModel = true;
    this.disableYear = true;
    this.showSubmodel1 = false;
    this.showSubmodel2 = false;
    this.showSubmodel3 = false;
    this.makes = this.initialData;
    this.selectedMakeKey = '';
    this.searchByVehicleService.clearVehicleSearch();
    localStorage.removeItem('vehiclename');
    this.comm.clearVehicle.emit(true);
  } 

  getlocalMakedata(){   
    this.getMakeData= this.searchByVehicleService.getMakeResponseData();
    if(this.getMakeData?.makes){   
      this.makes = this.getMakeData?.makes;
      this.MakeData = this.getMakeData; 
      this.initialData = this.makes;    
      this.disableMake = false;      
      this.fetchDropDownData();
    } else{
      this.fetchDropDownData();
    }  
  }
}
