import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';
import { Common } from '../interfaces/common.interface';
import { footers } from '../interfaces/footer.interface';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  private endpoint: string = environment.apiUrl;
  footerDataUpdate: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpClient) {}

  footerList(): Observable<Common<footers>>{
    return this.http.get<Common<footers>>(this.endpoint+"footers/all-menus");
  }

  earlyBirdUser(data: any) {
      return this.http.post(
        this.endpoint + '/api/v1/footers',
        data
      );
    }
}
