import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortByValue', standalone: true })
export class SortByValuePipe implements PipeTransform {
  transform(value: { [key: string]: any }): { key: string; value: any }[] {
    if (!value) return [];
    // Convert the object to an array and sort alphabetically by value
    return Object.entries(value)
      .map(([key, val]) => ({ key, value: val }))
      .sort((a, b) => a.value.localeCompare(b.value)); // Sort by value alphabetically
  }
}